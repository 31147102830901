// zn.js
export default {
  main: {
    视频教程: "视频教程",
    介绍: "介绍",
    联系我们: "联系我们",
    快速学习: "快速学习",
    快速入门: "快速入门",
    快速上手: "快速上手",
    入住办理指南: "入住办理指南",
    员工管理: "员工管理",
    设备管理: "设备管理",
    常见问题: "常见问题",
    发卡问题: "发卡问题",
    办理入住: "办理入住",
    房卡管理: "房卡管理",
    软件系统: "软件系统",
    设备: "设备",
    智能锁: "智能锁",
    主页: "主页",
    下载中心: "下载中心",
    使用指南: "使用指南",
    登录: "登录",
    注册: "注册",
    前台PC端: "前台PC端",
    windows前台端: "windows前台端",
    下载: "下载",
    管理端: "管理端",
    管理端APP: "管理端APP",
    保存至电脑: "扫码下载",
    宾客端: "宾客端",
    宾客端APP: "宾客端APP",
    发卡器驱动下载: "发卡器驱动下载",
    联系电话: "联系电话",
    联系邮箱: "联系邮箱",
    客服微信: "客服微信",
    用微信扫一扫: "用微信扫一扫，添加客户微信",
    这里是备案号: "粤ICP备13041556号-10",
    首页: "首页",
    多种开门方式安全标准多端应用云端智能化酒店管理产品:'多种开门方式、安全标准、多端应用、云端智能化酒店管理产品,',
    这是一段介绍: ` <p>JoyInn致力于提供一站式完整的智能酒店管理系统，以后台服务为核心，包括PC前台软件，后台Web管理，酒店管理APP，宾客APP四个组件，全角色覆盖酒店业务场景，帮助酒店降低成本，提升效率，追求最佳客户体验。</p>
    <p>JoyInn系统通过物联网技术集成门锁，网关，门禁，梯控，取电开关等多种智能设备。网关和其他设备之间采用低功耗Sub1G无线通信，网关与酒店内部网络及Internet之间支持有线以太网和无线Wifi两种方式，给客户提供有线网络稳定性和无线网络部署灵活性的选择。智能设备性价比高，安装简单，配置快速。设备状态可以轻松从云端获取，远程可以轻松设置设备的时钟和参数配置。系统后台服务采用边缘计算构架，支持云端协同，酒店本地服务和云中心数据自动同步。PC前台软件可以在网络故障下独立运行，全天候运营不中断，彻底解决断网焦虑。</p>
    <p>JoyInn为客人提供便捷入住流程，即时入住通知，入住无需见面。提供多种开锁方式，支持二维码，卡，蓝牙，密码，给宾客提供方便灵活选择。既可以使用传统的卡片方式，也可以用系统独特的二维码方式，宾客不用下载App，也可以一码通行。宾客App可以一键拨打前台，可以随时预约保洁服务和其他客房服务，任务状态可以实时查看。</p>
    <p>系统通过员工管理和权限管理，为酒店工作人员提供服务支撑。前台入住操作简单易用，集中式房态呈现，一键发送授权，降低前台工作负荷。系统通过任务流程自动化，实现高效的后勤管理和客房服务，为保洁人员和维修人员提供实时任务通知，保洁，维修及其他人员通过App可以接受并处理任务。多角色智能协作，极大降低沟通和管理成本，提高保洁和客房服务的质量和效率，给客人提供尊贵的入住体验。</p>
    <p>系统为酒店管理者提供web和App两种管理工具，管理者可以随时随地通过PC和手机，查看酒店运营情况，房态和设备状态，事件和告警，客户请求和服务结果，一切尽在掌握。</p>
    <p>总而言之，无论是已有正在运营的，还是新建的酒店或公寓，JoyInn系统都是以更低的成本和更短的周期实现智能化的理想选择。</p>
    `,
    JoyInn酒店帮助中心: "JoyInn酒店帮助中心",
    智能化酒店管理软件: "joyInn酒店适用于集中式酒店、公寓、民宿，是一款智能化酒店管理软件",
    点我: "点我",
    JoyInn酒店快速上手: "JoyInn酒店快速上手-1min",
    添加锁等视频教程: "下载与安装、创建酒店、新增客房、公共门、添加锁等视频教程",
    员工及设备管理: "快速上手、入住办理指南、员工及设备管理",
    软件使用过程中遇到的问题这里都可以解决: "软件使用过程中遇到的问题这里都可以解决",
    云端智能化酒店管理产品: "多种打开方式、5种主流语言、OWASP安全标准、多端应用、云端智能化酒店管理产品",
    下载与安装: "下载与安装",
    创建酒店: "创建酒店",
    新增客房: "新增客房",
    公共门: "公共门",
    加锁: "加锁",
    添加网关: "添加网关",
    添加取电开关: "添加取电开关",
    添加梯控: "添加梯控",
    新建员工: "新建员工",
    给客人发卡: "给客人发卡",
    给客人发蓝牙钥匙: "给客人发蓝牙钥匙",
    给客人发密码: "给客人发密码",
    续房: "续房",
    退房: "退房",
    发总卡: "发总卡",
    发卡操作: "发卡操作",
    挂失房卡: "挂失房卡",
    房卡: '房卡',
    中文: "中文",
    英文: "英文",


    一站式完整智能酒店管理方案: '一站式完整智能酒店管理方案',
    便捷入住流程即时客户通知: '便捷入住流程，即时客户通知',
    房态即时呈现一键发送授权入住无需见面: '房态即时呈现，一键发送授权，入住无需见面',
    涵盖PC前台软件后台Web管理酒店管理APP宾客APP: '涵盖PC前台软件，后台Web管理，酒店管理APP，宾客APP',
    全角色覆盖酒店管理场景集中式数据共享提高管理效率赋能酒店数字化和智能化: '全角色覆盖酒店管理场景，集中式数据共享，提高管理效率，赋能酒店数字化和智能化',
    多种开锁方式灵活组合: '  多种开锁方式 I 灵活组合',
    开锁方式p1: '支持二维码，卡，蓝牙，密码，给宾客提供极为方便的入住体验',
    开锁方式p2: '既可以使用传统的卡片方式，也可以用系统独特的二维码方式，',
    开锁方式p3: '宾客不用下载App，也可以一码通行',
    二维码介绍: '识别速度快，安全性、多管理角色支持，降低人工成本，让管理更高效',
    二维码: '二维码',
    密码: '密码',
    密码介绍: '密码门锁通常易于使用，不需要任何特殊技能或培训',
    卡: '卡',
    卡介绍: '安全，易用，兼容传统用户习惯',
    蓝牙: '蓝牙',
    蓝牙介绍: '设备功耗更低，链接稳定性更好，降低企业运营成本',
    部署灵活联网离线都支持: ' 部署灵活 I 联网离线都支持',
    离线支持p1: "设备安装简易，支持联网部署，远程一键配置",
    离线支持p2: "也支持无网络部署，用手机App通过二维码或蓝牙配置设备",
    随时随地管理: '随时随地管理',
    随时随地管理p1: '管理者可以随时随地通过PC和手机，查看酒店运营情况，',
    随时随地管理p2: '房态和设备状态，时间和告警，客户请求和服务结果，一切尽在掌握',
    自动化时钟校准: '自动化时钟校准',
    自动化时钟校准p1: '服务器通过自动周期执行，告警触发执行等方式来同步锁的时钟',
    自动化时钟校准p2: '员工也可以通过日常活动，如App蓝牙和二维码开锁等方式，自动校准锁时钟',
    联系电话: '联系电话',
    联系邮箱: '联系邮箱',
    JoyInn酒店公众号: 'JoyInn酒店公众号',
    客服微信号: '客服微信号',
    安装指南: '安装指南',
    快速开店: '快速开店',
    日常操作: '日常操作',
    运营管理: '运营管理',
    Joylnn酒店帮助中心:'Joylnn酒店帮助中心',
    JoyInn酒店系统:'JoyInn酒店系统',

    // 安装指南
    软件系统: '软件系统',
    软件系统h1: '1.酒上使用吗店软件可以在苹果电脑？',
    软件系统p1: '目前电脑端只支持 Windows 系统。',
    软件系统h2: '2.系统安装步骤',

    //快速开店
    入住办理指南: '入住办理指南',
    快速开店h1: '房态管理',
    快速开店p1: `
    <ul style="list-style-position: inside;
    padding-left: 0; ">
     <li>白色代表房间空着；</li>
     <li>蓝色的右上角有小人标的代表已经入住；</li>
     <li>紫色的右上角扫帚标代表需要清洁；</li>
     <li>右上角有时钟标的代表预订；</li>
     <li>右上角绿色地图标的代表预离；</li>
     <li>右上角有红色电池标代表该房间门锁电量低；</li>
     <li>右上角蓝色扳手标代表维修房；</li>
     <li>右上角红色感叹标该房间空房清卡异常；</li>
     <li>右上角红色卡标该房间空空房未清卡；</li>
    </ul>`,
    快速开店p12: '在工作台的右侧有「客房状态查询」，可以帮助我们快速了解房态及根据房态进行筛选。',
    快速开店h2: '办理入住',
    快速开店p21: '在工作台选择相应房间右键选择办理入住，即可为入住人办理入住。开锁支持三种方式：房卡、密码、蓝牙钥匙。',
    快速开店h3: '房卡',
    快速开店p31: '授权前请先确认发卡器连接正常。如发卡器连接失败，请参考 常见问题->设备 中的问题「发卡器连接失败是什么原因」。',
    快速开店p32: '当一个房间入住多个人时，可修改发卡数量。点击界面上确定，将房卡放置在发卡器上听到滴的一声再移开卡片，避免房卡发送失败。',
    快速开店h4: '续住',
    快速开店p41: '在工作台选择相应房间，右键从菜单选项中选择「续房」，设置新离店时间即可。',
    快速开店p42: `
    <ul style="list-style-position: inside;
    padding-left: 0; ">
     <li>「房卡」则需放置在房卡器上进行设置。</li>
     <li>「蓝牙钥匙」将自动延长有效期。</li>
     <li>「密码」若有网关将自动更新有效期，若无网关则需使用手机 APP 在门锁附近更改，或者发送信的密码。</li>
    </ul>
    `,
    快速开店p43: '也可通过工作台->续卡直接进行续卡，请注意续卡并不会续房。',
    快速开店h5: '退房',
    快速开店p51: '在工作台选择相应房间，右键从菜单选项中选择「退房」即可。',
    快速开店p52: '若是房卡，还可通过工作台->退卡进行退卡，请注意退卡并不会退房。',
    快速开店p53: '若房卡丢失，可不勾选「回收开锁权限」。然后再在授权设备->卡进行卡片挂失。',

    //日常操作
    快速上手: '快速上手',
    日常操作p11: '1.下载安装软件，并创建酒店账号。',
    日常操作p12: '点击获取下载方式',
    日常操作p13: '（链接到下载中心）',
    日常操作p2: '2.电脑端登录之后进入酒店即可添加。按照上方左边从到右的红框依次是添加楼栋、楼层、房间',
    日常操作p3: '3.设置房型和房价，可批量修改房间房型及房价，PC端，酒店→楼栋管理→客房管理均可修改，酒店→客房管理，批量修改',
    日常操作p4: '4.为宾客办理入住，在PC端工作台，选择相应房间右键选择【授权】录入入住人信息，选择开锁方式，即可完成办理入住。',

    //运营管理
    设备管理: '设备管理',
    运营管理h1: '房卡',
    运营管理p11: '可以用来开房间、梯控、取电开关，通常搭配发卡器使用。',
    运营管理p12: '房卡中设有 16 个扇区，JoyInn酒店系统默认使用了 1-10 扇区，剩余扇区可用作其它用途。。',
    运营管理p13: '在给员工发开门权限时支持 3 种授权方式：总卡、楼栋卡、楼层卡、梯控卡、自定义。',
    运营管理h2: '房卡',
    运营管理p21: '主要用来发放房卡，将发卡器的 USB 接口接入电脑即可使用。如果发卡器出现故障可检查',
    运营管理p22: '发卡器是否连接，可重新连接一下；',
    运营管理p23: '发卡器驱动是否安装；',
    运营管理p24: '或退出重新登陆，详细请查看该文档 常见问题-设备。',

    //常见问题
    问题101: '「101」发卡器操作异常#',
    文本101: `原因：可能是频繁关闭发卡弹窗导致<br>
    解决方案：退出软件重新打开，请勿关闭发卡弹窗`,
    问题102: '「102」 未发现卡，请重新操作#',
    文本102: `原因：周围是否有设备干扰酒店发卡器的射频信号，例如身份证读卡器<br>
    解决方案：其它设备与发卡器请保持至少 10 公分的距离`,
    问题1002: '「1002」发卡器连接失败#',
    文本1002: `解决方案：请更新软件最新版本后再尝试`,
    问题106: '「106」非本酒店卡#',
    文本106: `请按照以下步骤尝试解决问题：<br>
    1.当前卡片不是第一次使用，在其它软件或酒店账号下使用。请更换未使用过的卡片。<br>
    2.发卡器配套的 3 张工程卡暂时无法使用<br>
    3.请与「身份证读卡器」保持至少 10 公分的距离<br>
    4.未使用过的卡片，请先发卡，再使用「查卡」「续卡」「退卡」操作`,
    问题1003: '「1003」发卡器连接失败，请检查设备是否连接#',
    文本1003: `
    请在确保网络稳定的情况下，按照以下步骤尝试解决问题：<br>
    1.请与「身份证读卡器」保持至少 10 公分的距离<br>
    2.请检查发卡器与端口连接是否松动。<br>
    <ul style="list-style-position: inside;padding-left: 0; ">
     <li>检查图中数字 1数据线是否与发卡器连接有松动，可重新插拔</li>
     <li>检查图中数字 2与电脑 USB 接口是否有松动，可重新插拔或更换电脑 USB 接口</li>
     <li>图中数字 3请换一个数据线试试</li>
    </ul>
    `,
    文本1003p3:`3.请在发卡器连接电脑情况下检查驱动是否安装。以下分别是 win10 和 win7 的查看方式。若不存在图中红色框，请下载驱动，并完全解压到文件夹中
    安装。下载地址。`,
    图1驱动查看位置:'图1 win10 驱动查看位置',
    图2驱动查看位置:'图 2 win7 驱动查看',
    其他建议:`一般情况下电脑是 64 位，安装图中红色框第一个64即可。若无法运行，请尝试图中红色框中第二个x86。
    注：请将驱动压缩包完全解压到文件夹中再进行安装
    若以上都不行，请更换发卡器或电脑尝试。`,

    //升级中文字提示
    正在升级中:'正在升级中',
    正在升级中p1:'尊敬的访客您好<span style="color:#FD8E4D;">JOYINN</span>官网正在升级中',
    正在升级中p2:'请您稍后访问，感谢您对<span style="color:#FD8E4D">JOYINN</span>的支持',
  }
};