<template>
  <div>
    <!-- <footer style="display:none">
      <div class="footer_contect">
        <div style="">{{ $t('main.联系电话') }}:400-2535-5235</div>
        <div>{{ $t('main.联系邮箱') }}:</div>
      </div>
      <div class="footer_img">
        <div>{{ $t('main.JoyInn酒店公众号') }}</div>
        <el-image style="" fit="fit" :src="require('@/assets/Images/calibration.png')"></el-image>
      </div>
      <div class="footer_img">
        <div>{{ $t('main.客服微信号') }}</div>
        <el-image fit="fit" :src="require('@/assets/Images/calibration.png')"></el-image>
      </div>
    </footer> -->
    <div class="filings">
      <div class="lef">CopyRight © 2023-2024 JoyInn, Ltd. </div>
      <div class="filings_click" @click="toTerms">Terms</div>
      <div class="filings_click" @click="toPrivacy">Privacy</div>
      <div> Contact Us：<a href="mailto:support@ufunnetwork.com">support@ufunnetwork.com</a></div>
      <div><a target="_blank" href="https://beian.miit.gov.cn/">{{ $t('main.这里是备案号') }}</a></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  methods: {
    toPrivacy() {
      this.$router.push({ name: 'Privacy' })
    },
    toTerms() {
      this.$router.push({ name: 'Terms' })
    }
  }

}
</script>

<style lang="scss" scoped>
footer {
  width: 100vw;
  height: 230px;
  background-color: rgba(223, 223, 223, 1);
  color: rgba(16, 16, 16, 1);
  font-size: 18px;
  display: flex;
  justify-content: center;

  &>div {
    margin-top: 38px;
    margin-right: 190px;
    text-align: left;
  }

  .footer_contect {
    div:nth-child(1) {
      margin-bottom: 60px
    }
  }

  .footer_img {
    text-align: center;

    >div:nth-child(1) {
      margin-bottom: 20px
    }

    ::v-deep .el-image {
      width: 100px;
      height: 100px;
    }
  }
}

.filings {
  background-color: #000000;
  color: #ffffff;
  text-align: center;
  height: 2.86458333vw;
  line-height: 2.86458333vw;
  font-size: 0.83333333vw;
  display: flex;
  // justify-content: space-between;
  align-items: center;
  padding: 0 5vw;
  z-index: 999;

  div {
    margin-right: 28px;

    a {
      text-decoration: none;
      color: white;
    }
  }

  .filings_click {
    cursor: pointer;
  }

}
</style>