<template>
    <div class="container">
        <div class="header">
            <div class="login_list">
                <div class="img list"><img src="@/assets/Images/icon.png" alt=""></div>
                <div class="list" @click="toHome">
                    {{ $t("main.首页") }}
                </div>
                <div class="list" @click="toDownload">
                    {{ $t("main.下载中心") }}
                </div>
                <div class="list" @click="toIntroduction">
                    {{ $t('main.快速入门') }}
                </div>
                <div style="display:none" class="list" @click="toVideo">
                    {{ $t('main.视频教程') }}
                </div>
            </div>
            <div class="header_right">
                <div class="lang">
                    <img src="@/assets/Images/stLine-network.png" style="height:1.04166666vw;width:1.04166666vw" alt="">
                    <div>
                        <div style="margin-bottom: 10px;" @click="changeZhLang">简体中文</div>
                        <div @click="changeEnLang">English</div>
                    </div>
                </div>
                <div class="regest" @click="toAdmin">{{ $t('main.登录') }}&nbsp;&nbsp;|&nbsp;&nbsp;{{ $t('main.注册') }}</div>
            </div>

        </div>
        <div class="main">
            <router-view></router-view>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Footer from '@/components/Footer'
export default {
    components: {
        Footer
    },
    name: 'Home',
    data() {
        return {
            src: "@/assets/Images/icon.png",
            // language: localStorage.getItem('lang') || this.$i18n.locale
            language: localStorage.getItem('lang') || this.$i18n.locale
        }
    },
    created() {
        localStorage.setItem("lang", this.$i18n.locale)
    },
    methods: {
        changeZhLang() {
            this.$i18n.locale = 'zh'
            localStorage.setItem("lang", "zh")
            // location.reload()
        },
        changeEnLang() {
            this.$i18n.locale = 'en'   // 设置当前语言
            localStorage.setItem("lang", "en")
            // location.reload()
        },
        toHome() {
            this.$router.push("/home/homePage")
        },
        toDownload() {
            this.$router.push("/home/download")
        },
        toIntroduction() {
            // this.$router.push("/home/introduction")

            //直接跳转到入门的页面
            this.$router.push("/guideDeatil/introduce")
        },
        toVideo() {
            this.$router.push("/home/video")
        },
        toAdmin(){
            window.open('https://console.joyinn.net/')
        }
    }
}

</script>

<style lang="scss" scoped>
.container {
    height: 100%;
    width: 100%;
    overflow: hidden;
    // border: 1px solid red;
    .header {
        display: flex;
        justify-content: space-between;
        height: 100px;

        .login_list {
            display: flex;

            .img {
                // margin-top: 16px;
                margin-right: 100px;
                margin-left: 24px;
                // margin-bottom: 30px;

                img {
                    height: 50px;
                    width: 50px;
                    border-radius: 6px;
                }
            }

            .list {
                height: 100%;
                line-height: 100%;
                // margin-top: 26px;
                display: flex;
                align-items: center;
                margin-right: 80px;
                color: rgba(16, 16, 16, 1);
                font-size: 18px;
                cursor: pointer;
            }
        }

        .header_right {
            display: flex;
            align-items: center;

            .lang {
                display: flex;
                height: 50px;
                // align-items: center;
                color: rgba(16, 16, 16, 1);
                font-size: 14px;
                margin-top: 10px;
                margin-right: 42px;
                text-align: center;

                img {
                    margin-right: 8px;
                }

                div {
                    height: 20px;
                    line-height: 20px;
                    cursor: pointer;
                }
            }

            .regest {
                color: rgba(16, 16, 16, 1);
                font-size: 14px;
                margin-right: 56px;
                cursor: pointer;
            }
        }
    }
}
</style>