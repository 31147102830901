// en.js
export default {
    main: {
        视频教程: "Video Tutorials",
        介绍: "Introduction",
        联系我们: "Contact",
        快速学习: "Quickstart",
        快速入门: "Quickstart",
        常见问题: "FAQ",
        主页: "Home",
        下载中心: "Download Center",
        使用指南: "Manual",
        登录: "Login",
        注册: "Register",
        前台PC端: "PC FrontDesk",
        windows前台端: "JoyInn Frontdesk Software(Windows)",
        下载: "Download",
        管理端: "Admin APP",
        管理端APP: "APP for Admin",
        保存至电脑: "Scan QR to Download",
        宾客端: "Guest APP",
        宾客端APP: "APP for Guest",
        发卡器驱动下载: "Download Card Reader Driver",
        联系电话: "Telephone",
        联系邮箱: "Contact Us",
        客服微信: "Wechat Service",
        这里是备案号: "粤ICP备13041556号-10",
        首页: "Home",
        多种开门方式安全标准多端应用云端智能化酒店管理产品: 'Cloud based Smart Hotel Management System with industrial safety, across multiple screens, and supporting multiple unlocking methods.',
        这是一段介绍: ` <p>
		JoyInn dedicates to provide one stop smart hotel management system. Based core backend services, PC Frontdesk, Web console, 
		Admin App and Guest App cowork as an integrated system. It fully cover Smart Hotel Management with multiple roles and automatical workflow to 
		enable hotel with high efficiency and low cost. At the same time, achieve very good guest experience.
        </p>
        <p>
		JoyInn system integrates locks, gateway, access control, elevator control and power switch with IOT technology. 
		Gateway communicates with other devices via low energy Sub1G wireless communication. Meanwhile it connect to hotel 
		intranet and outside internet through Wifi or Ethernet Cable. This enable hotel customers a flexible choice according
		their situation. The devices are cost effective, easy to installed and quickly configured. The status of devices are
		available in Cloud which enable easily and securely configuration and clock synchroniztion. System services adopt 
		central and edge computing framework. Data synchronization is automatically executed in backend. PC frontdesk can work
		normally in case the internet access is broken. This enable 7x24 continuous operation for hotel and fully get rid of network
		dependency.
        </p>
        <p>
		JoyInn provides guest very convenient check-in and instant notification. It provide multiple authoriztions such as QR code, card,
		bluetooth and Pin for guest choice. Besides traditional card support, its unique QR code method is more convenient and efficient.
		Downloading Guest App is not a must. QR code can satisfy all access needs. Of course, with Guest App, Guests can call the frontdesk, 
		oder the housekeeping and other room services. They can also check the services status in real-time. 
        </p>
        <p>
		Staff management and authorization functions of the system provide the solid foundation for Hotel services. Frontdesk operations, such as
		real-time room status, quick authorization and simple check-in operation, greatly reduce the staff workload. Automatical
		workflow combined with real-time notification to housekeeper and service staffs helps to achieve efficient logistics and room services.
		Multiple roles can cooperate with App workflow, accepting and processing tasks. Thus reduces the communication and management cost,
		achieve high service quality and efficiency and provide privilege experience to guest.
        </p>
        <p>
		System provides hotel managers with web console and App. Managers can check Hotel operation with PC and Mobile at anytime, anywhere.
        Room/device status, events/alarms and guest requests/service results are all in hand
        </p>
        <p>
		 In summary, JoyInn is an ideal choice for existing or new hotels who want to achieve intelligent transformation with 
		 low cost and short transition time.
        </p>
    `,
        中文: "chinese",
        英文: "english",


        一站式完整智能酒店管理方案: 'One Stop Smart Hotel Management Solution',
        便捷入住流程即时客户通知: 'Quick Checkin, Instant Guest Notification',
        房态即时呈现一键发送授权入住无需见面: 'Room status at a glance , One click to authorize, Checkin without face-to-face',
        涵盖PC前台软件后台Web管理酒店管理APP宾客APP: 'Including PC Frontdesk，Web Admin Console，Admin APP adn Guest APP',
        全角色覆盖酒店管理场景集中式数据共享提高管理效率赋能酒店数字化和智能化: 'Fully cover Smart Hotel Management scenarios with multiple roles support.Central data sharing and automatical workflow enable high efficiency',
        多种开锁方式灵活组合: 'Multiple unlocking methods combinations',
        开锁方式p1: 'QR Code, Card, Bluetooth and Pin combination provide guest convenient choices',
        开锁方式p2: ' Besides traditional card support, unique QR code method is more convenient and efficient ',
        开锁方式p3: ' Guest App is not a must. QR code can satisfy access needs',
        二维码介绍: 'Fast, secure and multiple roles support to achieve low cost and high efficiency',
        二维码: 'QR Code',
        密码: 'Pin',
        密码介绍: 'Keypad lock easy to use',
        卡: 'Card',
        卡介绍: 'Secure，easy to use,  traditional user experience',
        蓝牙: 'Bluetooth',
        蓝牙介绍: 'Low energy device, stable connection, low cost of ownership',
        部署灵活联网离线都支持: ' Flexible deployment, online and offline',
        离线支持p1: "Easy Installation, support online deployment with remote setup",
        离线支持p2: "Also support offline deployment, configuring device with Bluetooth or QR code",
        随时随地管理: 'Management Anytime Anywhere',
        随时随地管理p1: ' Managers can check Hotel operation with PC and Mobile at anytime, anywhere',
        随时随地管理p2: 'Room/device status, events/alarms and guest requests/service results are all in hand',
        自动化时钟校准: 'Automatic Clock Synchronization',
        自动化时钟校准p1: 'Cloud automatically synchronize the clock of locks via remote execution either periodically or alarm triggling ', 
        自动化时钟校准p2: 'Daily bluetooth and QRcode activities by staffs automatically synchronize the clock', 
        联系电话: 'Phone',
        联系邮箱: 'Email',
        JoyInn酒店公众号: 'JoyInn Public Account',
        客服微信号: 'Client Service via Wechat',
        安装指南: 'Installation Guide',
        快速开店: 'Quick Hotel Setting',
        日常操作: 'Daily Operation',
        运营管理: 'Operation Management',
        Joylnn酒店帮助中心: 'Joylnn System Help Center',
        JoyInn酒店系统: 'JoyInn System',

        //升级中文字提示
        正在升级中: 'In Construction',
        正在升级中p1: `Dear Vistor<span style="color:#FD8E4D;"> JoyInn's tutorials</span> is in construction`,  
        正在升级中p2: 'Will update soon.Thanks for your support to <span style="color:#FD8E4D"> JoyInn </span>!',   
    }
}